import {
  Box,
  Drawer,
  Paper,
  useMediaQuery,
} from '@mui/material';

import NextLink from 'next/link';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { selectCurrentTenant } from '@slices/tenant';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Scrollbar from './Scrollbar';
import MainSidebarSection from './MainSidebarSection';
import Logo from './Logo';
import AuthAclGuard from './AuthAclGuard';

const MainSidebarRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  [theme.breakpoints.up('lg')]: {
    gridArea: ' 1 / 1 / 3 / 2',
  },
}));

export default function MainSidebar(props) {
  const { onClose, open, sections } = props;
  const tenant = useSelector(selectCurrentTenant);
  const router = useRouter();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
    noSsr: true,
  });

  const handlePathChange = () => {
    if (!router.isReady) {
      return;
    }

    if (open) {
      onClose?.();
    }
  };

  useEffect(
    handlePathChange,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router.isReady, router.asPath],
  );

  const content = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': {
          height: '100%',
        },
        '& .simplebar-scrollbar::before': {
          bgcolor: (theme) => (theme.palette.sidebar?.scrollbar?.main ?? 'background.paper'),
        },
      }}
    >
      <MainSidebarRoot>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="flex-start"
          sx={{
            px: 3,
            py: 1,
            mb: 1,
            height: 64,
            flex: '0 0 64px',
            bgcolor: 'background.leftLogo',
            boxShadow: (theme) => theme.shadows[3],
          }}
        >
          <NextLink
            href="/"
            legacyBehavior
            passHref
          >
            <a style={{ display: 'block' }}>
              <Logo
                height={42}
                sx={{ display: 'block' }}
                variant="dark"
                width={121}
              />
            </a>
          </NextLink>
        </Box>
        <Box sx={{ flexGrow: 1, pb: 5 }}>
          {sections.map((section) => (
            <AuthAclGuard
              key={section.title}
              requiredAcl={section.acl ?? []}
            >
              <MainSidebarSection
                path={router.asPath}
                sx={{
                  mt: 1,
                  '& + &': {
                    mt: 1.5,
                  },
                }}
                tenant={tenant}
                {...section}
              />
            </AuthAclGuard>
          ))}
        </Box>
      </MainSidebarRoot>
    </Scrollbar>
  );

  if (lgUp) {
    return (
      <Paper
        square
        sx={{
          gridArea: ' 1 / 1 / 3 / 2',
          backgroundColor: 'background.leftSidebar',
          borderRightColor: 'divider',
          borderRightStyle: 'solid',
          borderRightWidth: (theme) => (theme.palette.mode === 'dark' ? 1 : 0),
          color: '#FFFFFF',
        }}
      >
        {content}
      </Paper>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.900',
          color: '#FFFFFF',
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
}

MainSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  sections: PropTypes.array,
};

MainSidebar.defaultProps = {
  onClose: null,
  open: false,
  sections: [],
};
